<template>
  <b-card>
    <div class="company-info-card-container">
        <div
          class="site-info-card"
          :class="{ 'expanded': expandedCard.info }"
        >
          <h2>
            Company Information
          </h2>
        <table class="mt-2 mt-xl-0 w-100">
          <tr v-if="companyData.parent && companyData.parent.type && companyData.parent.type !== 'simrush'">
            <th class="pb-50 pr-50">
              <feather-icon
                icon="ArrowRightCircleIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Parent company:</span>
            </th>
            <td class="pb-50">
              <a :href="`/company-management/summary/${companyData.parent.id}`">
                {{ companyData.parent.name }}
              </a>
            </td>
          </tr>
          <tr>
            <th class="pb-50 pr-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Name:</span>
            </th>
            <td class="pb-50">
              {{ companyData.name }}
            </td>
          </tr>
          <tr v-if="companyData.registered_number">
            <th class="pb-50 pr-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Registered Number:</span>
            </th>
            <td class="pb-50">
              {{ companyData.registered_number }}
            </td>
          </tr>
          <tr v-if="companyData.vat_id">
            <th class="pb-50 pr-50">
              <feather-icon
                icon="HomeIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">VAT ID:</span>
            </th>
            <td class="pb-50">
              {{ companyData.vat_id }}
            </td>
          </tr>
          <tr v-if="companyData.billingTerm">
            <th class="pb-50 pr-50">
              <feather-icon
                icon="ActivityIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Billing Terms:</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ companyData.billingTerm.name }}
            </td>
          </tr>
          <tr v-if="companyData.currency">
            <th class="pb-50 pr-50">
              <feather-icon
                icon="DollarSignIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Currency:</span>
            </th>
            <td class="pb-50">
              {{ companyData.currency.name }}
            </td>
          </tr>
          <tr v-if="telephone">
            <th class="pb-50 pr-50">
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Telephone:</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ telephone }}
            </td>
          </tr>
          <tr>
            <th class="pb-50 pr-50">
              <feather-icon
                icon="MailIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Email</span>
            </th>
            <td class="pb-50">
              {{ companyData.email }}
            </td>
          </tr>
          <tr v-if="companyData.web">
            <th class="pb-50 pr-50">
              <feather-icon
                icon="LinkIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Web:</span>
            </th>
            <td class="pb-50">
              {{ companyData.web }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="TypeIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Type:</span>
            </th>
            <td>
              {{ companyData.type.name }}
            </td>
          </tr>
        </table>
        <b-button
          v-if="$can('update', 'profile-company')"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-1"
          @click="() => $router.push({ name: 'company-profile-update-info'})"
        >
          Edit
        </b-button>
          <button
            class="site-info-card__expand"
            :class="{ 'expanded': expandedCard.info }"
            @click="expand('info')"
          >
            <feather-icon icon="ChevronDownIcon" />
          </button>
      </div>
        <div
          class="site-info-card long-expand"
          :class="{ 'expanded': expandedCard.address }"
        >
          <h2>
            Company Address
          </h2>
          <h6>Shipping Address</h6>
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="MapPinIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Country:</span>
              </th>
              <td class="pb-50">
                {{ companyData.shipping.country.name }}
              </td>
            </tr>
            <tr v-if="companyData.shipping.state">
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="MapIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">State:</span>
              </th>
              <td class="pb-50">
                {{ companyData.shipping.state }}
              </td>
            </tr>
            <tr>
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="MapIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">City:</span>
              </th>
              <td class="pb-50">
                {{ companyData.shipping.city }}
              </td>
            </tr>
            <tr v-if="companyData.shipping.address1">
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="MapIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Address 1:</span>
              </th>
              <td class="pb-50">
                {{ companyData.shipping.address1 }}
              </td>
            </tr>
            <tr v-if="companyData.shipping.address2">
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="MapIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Address 2:</span>
              </th>
              <td class="pb-50">
                {{ companyData.shipping.address2 }}
              </td>
            </tr>
            <tr v-if="companyData.shipping.postal_code">
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="MapIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Postal Code:</span>
              </th>
              <td class="pb-50">
                {{ companyData.shipping.postcode }}
              </td>
            </tr>
          </table>
          <h6 class="mt-2">
            Billing Address
          </h6>
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="MapPinIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Country:</span>
              </th>
              <td class="pb-50">
                {{ companyData.billing.country.name }}
              </td>
            </tr>
            <tr v-if="companyData.billing.state">
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="MapIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">State:</span>
              </th>
              <td class="pb-50">
                {{ companyData.billing.state }}
              </td>
            </tr>
            <tr>
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="MapIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">City:</span>
              </th>
              <td class="pb-50">
                {{ companyData.billing.city }}
              </td>
            </tr>
            <tr v-if="companyData.billing.address1">
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="MapIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Address 1:</span>
              </th>
              <td class="pb-50">
                {{ companyData.billing.address1 }}
              </td>
            </tr>
            <tr v-if="companyData.billing.address2">
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="MapIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Address 2:</span>
              </th>
              <td class="pb-50">
                {{ companyData.billing.address2 }}
              </td>
            </tr>
            <tr v-if="companyData.billing.postal_code">
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="MapIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Postal Code:</span>
              </th>
              <td class="pb-50">
                {{ companyData.billing.postcode }}
              </td>
            </tr>
          </table>
          <button
            class="site-info-card__expand"
            :class="{ 'expanded': expandedCard.address }"
            @click="expand('address')"
          >
            <feather-icon icon="ChevronDownIcon" />
          </button>
        </div>
        <div
          class="site-info-card"
          :class="{ 'expanded': expandedCard.invite }"
        >
          <h2>Invite user</h2>
        <!-- form -->
        <validation-observer ref="userInvite">
          <b-form>
            <b-form-group
              label="User Email"
              label-for="email"
              label-class="label-class"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                vid="email"
                rules="required|email|max:255"
              >
                <b-form-input
                  v-model="form.email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Email"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              v-if="userRoles.length > 0"
              label="User Role"
              label-for="user-type"
              label-class="label-class"
            >
              <validation-provider
                #default="{ errors }"
                name="user-type"
                vid="user_role_id"
                rules="required"
              >
                <v-select
                  v-model="form.user_role_id"
                  :options="userRoles"
                  :reduce="user_role_id => user_role_id.id"
                  placeholder="Select user role"
                  label="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                :disabled="loading"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-form-group>
          </b-form>
        </validation-observer>

        <button
            class="site-info-card__expand"
            :class="{ 'expanded': expandedCard.invite }"
            @click="expand('invite')"
          >
            <feather-icon icon="ChevronDownIcon" />
          </button>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { mapFields } from 'vuex-map-fields'
import { required, email, max } from '@validations'
import Ripple from 'vue-ripple-directive'
import parsePhoneNumber from 'libphonenumber-js'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BButton,
    vSelect,
    BForm,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
  },
  props: {
    companyData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      id: 0,
      form: {
        email: '',
        user_role_id: null,
      },
      required,
      emailRule: email,
      max,

      expandedCard: {
        info: false,
        address: false,
        invite: false,
      },
    }
  },
  computed: {
    ...mapState('companyManagement', {
      countries: 'countries',
      companyStatistics: 'companyStatistics',
    }),
    ...mapFields('invite', [
      'userRoles',
    ]),
    telephone() {
      if (this.companyData.phone_country_iso) {
        const phoneNumber = parsePhoneNumber(this.companyData.phone_number, this.companyData.phone_country_iso)
        return phoneNumber.number
      }
      return `${this.companyData.phone_number}`
    },
  },
  async created() {
    await this.fetchUserRoles()
  },
  methods: {
    ...mapActions('companyManagement', [
      'getcompanyData',
      'getCompanyStatistics',
    ]),
    ...mapActions('invite', [
      'fetchUserRoles',
    ]),
    expand(name) {
      this.expandedCard[name] = !this.expandedCard[name]
    },
    async validationForm() {
      const valid = await this.$refs.userInvite.validate()
      if (valid) {
        try {
          const payload = { ...this.form }
          payload.company_id = this.companyData.id
          payload.is_new_company = 0

          await this.$http.post('/user/invite/store', {
            ...payload,
          })

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        } catch (error) {
          const { status } = error.response

          if (!status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }
          if (status === 422) {
            const { fields } = error.response.data

            this.$refs.userInvite.setErrors(fields)
          }
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
