<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="companyData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching company data
      </h4>
      <div class="alert-body">
        No company found with this company id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'company-list'}"
        >
          Company List
        </b-link>
        for other companies.
      </div>
    </b-alert>

    <template v-if="companyData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
        >
          <company-view-info-card
            :company-data="companyData"
          />
        </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>

import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import CompanyViewInfoCard from '../components/CompanyViewInfoCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    'company-view-info-card': CompanyViewInfoCard,

  },
  data() {
    return {
      companyData: null,
    }
  },
  async created() {
    try {
      const { data } = await this.$http.get('/company/profile')

      this.companyData = data.data
    } catch (error) {
      console.log('error :>> ', error)
    }
  },
}
</script>

<style>

</style>
